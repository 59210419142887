import { Link } from '@remix-run/react'

export function Footer({
  textColor,
  colorScheme,
}: {
  textColor: string
  colorScheme: string[]
}) {
  colorScheme = colorScheme ?? [
    '#000',
    '#fff',
    '#f3f4f6',
    '#e5e7eb',
    '#9ca3af',
    '#6b7280',
    '#374151',
    '#1f2937',
  ]
  textColor = textColor ?? colorScheme[6]
  return (
    <footer className="z-10 flex w-full flex-col items-center justify-center gap-8 py-6">
      <div className="flex w-full shrink-0 flex-col items-center gap-2 border-t px-4 py-6 sm:flex-row md:px-6">
        <p className="text-xs" style={{ color: textColor }}>
          &copy; 2024 immotr.ee,. All rights reserved.
        </p>
        <nav className="flex gap-4 sm:ml-auto sm:gap-6" style={{ color: colorScheme[9] }}>
          <Link to="#" className="text-xs underline-offset-4 hover:underline">
            Privacy Policy
          </Link>
          <Link to="#" className="text-xs underline-offset-4 hover:underline">
            Terms of Service
          </Link>
        </nav>
      </div>
    </footer>
  )
}
